
@use "sass:map";
@import "vuetify/src/styles/styles.sass";

.language {
  width: 80px;
  padding-left: 6px;
  transition: background-color 0.5s;
  border-radius: 4px;
}

header.theme--light .language:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

header.theme--dark .language:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

header.theme--light.v-app-bar.v-toolbar {
  background-color: map.get($material-light, "background");
}

header.theme--dark.v-app-bar.v-toolbar {
  background-color: map.get($material-dark, "background");
}
