
$brand-color: #000;

.brand {
  height: 50vw;
  width: unset;
  min-height: 125px;
  max-height: 250px;
  transition: fill 0.5s;
  fill: scale-color($brand-color, $lightness: 50%);

  &:hover {
    fill: $brand-color;
  }
}

.theme--dark .brand {
  &:hover {
    fill: invert($brand-color);
  }
}
